.code-border {
  background: linear-gradient(111.93deg,rgba(14,26,45,.24) -1.4%,rgba(17,30,50,.38) 104.96%);
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right bottom,#ffffff38,#ffffff00);
}

.homepage_bg {
  background: url("../../../assets/Images/bghome.svg");
}
