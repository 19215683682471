/* write your css here */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .gradient-custom {
      background: linear-gradient(123.77deg, #8a2be2 -6.46%, orange 59.04%, #f8f8ff 124.53%);
    }
  }
  
html,body{
    overflow-x: hidden;
}

.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid #f1f2ff;
  animation: spinner-bulqg1 0.8s infinite linear alternate,
    spinner-oaa3wk 1.6s infinite linear;
}

@keyframes spinner-bulqg1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }

  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }

  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes spinner-oaa3wk {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

.lable-style {
  @apply text-[14px] text-richblack-5;
}
.form-style {
  @apply rounded-lg bg-richblack-700 p-3 text-[16px] leading-[24px] text-richblack-5 shadow-[0_1px_0_0] shadow-white/50 placeholder:text-richblack-400 focus:outline-none;
}

.section_heading {
  --tw-text-opacity: 1;
  color: rgb(241 242 255/var(--tw-text-opacity));
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

@media (min-width: 1024px){
  .section_heading {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
}

.yellowButton {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(255 214 10/var(--tw-bg-opacity));
  border-radius: 0.375rem;
  color: rgb(0 8 20/var(--tw-text-opacity));
  cursor: pointer;
  font-weight: 600;
  padding: 8px 20px;
}

.blackButton {
  --tw-bg-opacity: 1;
  background-color: rgb(22 29 41/var(--tw-bg-opacity));
  border-radius: 0.375rem;
  cursor: pointer;
  font-weight: 600;
  padding: 8px 20px;
}